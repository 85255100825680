"use client";


import React, { ButtonHTMLAttributes } from "react";

export type ButtonColors =
  | "primary"
  | "primary-variant-strong"
  | "secondary"
  // | "dark"
  | "outline-primary"
  | "outline-secondary"
  | "outline-accepted"
  | "outline-dark"
  // | "link-primary"
  | "link-secondary"
  | "link-muted"
  // | "link-warning"
  | "link-dark"
  | "accent"
  | "accepted"
  | "maybe"
  | "declined"
  ;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  color?: ButtonColors;
  inline?: boolean;
  loading?: boolean;
  /** use 'primary' only for actions which actually trigger the users main action, defaults to 'outline-secondary' */
  onClick?: React.MouseEventHandler<HTMLElement>;
}

// export interface AnchorButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement>, SharedButtonProps {
//   /** use 'primary' only for actions which actually trigger the users main action, defaults to 'outline-secondary' */
//   type?: "button" | "submit" | "reset" | undefined
//   color?: ButtonColors,
//   onClick?: React.MouseEventHandler<HTMLElement>;
// }
//
//
//
//
// export type ButtonType = ButtonProps | AnchorButtonProps
//
// export const Button = forwardRef<HTMLElement, ButtonType>(({
//   children,
//   className,
//   color = "secondary",
//   href,
//   ...attr
// }, ref) => {
//   const buttonRef = (ref as any) || React.createRef<HTMLElement>();
//
//
//   const classNameHasFlexClass =  className ? new RegExp(/\b(?:[^-]|^)flex\b/).test(className) : false
//
//   const attributes = {
//     className: `
//       ${ !classNameHasFlexClass ? "inline-flex" : "" }
//       button button--${color}
//       ${className || ""}`,
//       ...attr
//
//   }
//
//   if (href) {
//     return <Link ref={buttonRef} href={href} {...attributes}>{children}</Link>
//   } else {
//     return <button ref={buttonRef} {...attributes} >
//       {children}
//     </button>
//   }
// })
//
// export default Button;

export default function Button({
  children,
  className,
  color = "secondary",
  inline,
  loading = false,
  ...attr
}: ButtonProps) {
  const classNameHasFlexClass = className
    ? new RegExp(/\b(?:[^-]|^)flex\b/).test(className)
    : false;
  const colorClassName = {
    "primary": "button--primary",
    "primary-variant-strong": "button--primary-variant-strong",
    "secondary": "button--secondary",
    "outline-primary": "button--outline-primary",
    "outline-secondary": "button--outline-secondary",
    "outline-accepted": "button--outline-accepted",
    "outline-dark": "button--outline-dark",
    "link-secondary": "button--link-secondary",
    "link-muted": "button--link-muted",
    "link-dark": "button--link-dark",
    "accent": "button--accent",
    "accepted": "button--accepted",
    "maybe": "button--maybe",
    "declined": "button--declined",
  }

  const attributes = {
    className: `
      ${!classNameHasFlexClass ? "inline-flex" : ""}
      button
      ${colorClassName[color]}
      ${inline ? "button--inline" : ""}
      ${className || ""}`,
    ...attr,
  };

  return (
    <button disabled={loading ?? attributes.disabled} {...attributes}>
      {loading && <div className="absolute inset-0 backdrop-blur-sm flex items-center justify-center"><div className="loading-spinner" /></div>} {children}
    </button>
  );
}
